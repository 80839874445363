<template>
    <div class="content1">
        <div class="header">
            <div class="header-title">{{ $t('bigScreen.title') }}</div>
            <div class="cur-time">
                <div class="operation">
                    <el-cascader style="width: 100%;" :clearable="true" v-model="searchAgentPath"
                        :props="formData.props" :options="cascade_options_direct" popper-append-to-body="false"
                        placement='left-start' size="small" />

                    <!-- <el-select class="inp2" v-model="searchAgentPath" :placeholder="$t('g.choose')"
                        size="small">
                        <el-option v-for="item in operators" :key="item.value" :label="item.label" :value="item.value"
                            :props="formData.props" :options="cascade_options_direct">
                        </el-option>
                    </el-select> -->
                </div>
                <div class="time">
                    <el-date-picker class="inp" v-model="searchAllTime" value-format="YYYY-MM-DD" size="small"
                        type="daterange" unlink-panels :range-separator="$t('g.to')"
                        :start-placeholder="$t('g.start_date')" :end-placeholder="$t('g.end_date')"
                        :shortcuts="shortcuts">
                    </el-date-picker>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="left">
                <div class="l1 card">
                    <div class="chart-title">
                        <div>{{ $t('bigScreen.total_ebox') }}</div>
                        <div>{{ $t('bigScreen.unit') }}：{{ $t('bigScreen.PCS') }}</div>
                    </div>
                    <div class="chart-content">
                        <echartONe :OnlineBox="leftData.OnlineBox" :OfflineBox="leftData.OfflineBox"
                            :NoReleaseBox="leftData.NoReleaseBox"></echartONe>
                    </div>
                </div>
                <div class="l2 card">
                    <div class="chart-title">
                        <div>{{ $t('bigScreen.total_battary') }}</div>
                        <div>{{ $t('bigScreen.unit') }}：{{ $t('bigScreen.PCS') }}</div>
                    </div>
                    <div class="chart-content">
                        <leftTwoEchart :InDevCount="leftData.InDevCount" :InIdleCount="leftData.InIdleCount"
                            :InManCount="leftData.InManCount" :TatolCount="leftData.TatolCount"></leftTwoEchart>
                    </div>
                </div>
                <div class="l3 card">
                    <div class="chart-title">
                        <div>{{ $t('bigScreen.total_emotor') }}</div>
                        <div>{{ $t('bigScreen.unit') }}：{{ $t('bigScreen.PCS') }}</div>
                    </div>
                    <div class="chart-content">
                        <echartThree></echartThree>
                    </div>
                </div>
            </div>
            <div class="center">
                <div class="map" id="map">
                    <div id="bigScreenMap" style="width:100%;height: 100%;">

                    </div>
                    <div :class="enLang ? 'en-select' : 'select'">
                        <div>
                            <el-radio v-model="formData.type" label="all">{{ $t('bigScreen.all') }}</el-radio>
                        </div>
                        <div>
                            <el-radio v-model="formData.type" label="all">{{ $t('bigScreen.emotor') }}</el-radio>
                        </div>
                        <div>
                            <el-radio v-model="formData.type" label="ebox">{{ $t('bigScreen.ebox') }}</el-radio>
                        </div>
                        <div>
                            <el-radio v-model="formData.type" label="cbox">{{ $t('bigScreen.cbox') }}</el-radio>
                        </div>
                        <div>
                            <el-switch v-model="formData.online" active-color="#13ce66">
                            </el-switch>
                            <span class="switch-txt switch-txt-online">{{ $t('bigScreen.online') }}</span>
                        </div>

                        <div>
                            <el-switch v-model="formData.offline" active-color="#ff4949">
                            </el-switch>
                            <span class="switch-txt switch-txt-offline">{{ $t('bigScreen.offline') }}</span>
                        </div>

                    </div>
                </div>
                <div class="order">
                    <div class="item">
                        <span>Id</span>
                        <span>{{ $t('bill.nickname') }}</span>
                        <span>{{ $t('bill.status') }}</span>
                        <span>{{ $t('member.mobile') }}</span>
                    </div>
                    <vue3-seamless-scroll :list="formData.tableData" class="scroll" :step="0.5" :hover="true">

                        <div class="item" v-for="(item, index) in formData.tableData" :key="index">
                            <span>{{ item.Id }}</span>
                            <span>{{ item.Nickname }}</span>
                            <span>{{ item.Money }}</span>
                            <span>{{ item.Mobile }}</span>
                        </div>
                    </vue3-seamless-scroll>
                </div>
            </div>
            <div class="right">
                <div class="r1">
                    <div class="chart-title">
                        <div class="t">{{ $t('bigScreen.total_revenue') }}</div>
                        <div class="t" style="width:120px">{{ $t('bigScreen.daily_earnings') }}</div>
                    </div>
                    <div class="chart-content">
                        <div>
                            <div>{{ $t('bigScreen.amount_received') }}</div>
                            <div>{{ incomeData.allEarnings }}</div>
                        </div>
                        <div>
                            <div>{{ $t('bigScreen.today_income') }}</div>
                            <div>{{ incomeData.todayEarnings }}</div>
                        </div>
                        <div>
                            <div>{{ $t('bigScreen.refund_amount') }}</div>
                            <div>{{ incomeData.allRefund }}</div>
                        </div>
                        <div>
                            <div>{{ $t('bigScreen.today_refund') }}</div>
                            <div>{{ incomeData.todayRefund }}</div>
                        </div>
                    </div>
                </div>
                <div class="r2">
                    <div class="chart-title">
                        <div class="t">{{ $t('bigScreen.user_data') }}</div>
                        <!-- <div class="time">
                            <el-date-picker class="inp" v-model="formData.valTime2" value-format="YYYY-MM-DD"
                                size="small" type="daterange" unlink-panels :range-separator="$t('g.to')"
                                :start-placeholder="$t('g.start_date')" :end-placeholder="$t('g.end_date')"
                                :shortcuts="shortcuts">
                            </el-date-picker>
                        </div> -->
                    </div>
                    <div class="chart-content">
                        <div>
                            <div>{{ $t('bigScreen.registered_users') }}</div>
                            <div>{{ userData.totalUser }}</div>
                        </div>
                        <div>
                            <div>{{ $t('bigScreen.valid_users') }}</div>
                            <div>{{ userData.validUser }}</div>
                        </div>
                        <div>
                            <div>{{ $t('bigScreen.added_users') }}</div>
                            <div>{{ userData.newUser }}</div>
                        </div>
                    </div>
                </div>
                <div class="r3">
                    <div class="chart-title">
                        <div class="t">{{ $t('bigScreen.operation_data') }}</div>
                        <!-- <div class="time">
                            <el-date-picker class="inp" v-model="formData.valTime2" value-format="YYYY-MM-DD"
                                size="small" type="daterange" unlink-panels :range-separator="$t('g.to')"
                                :start-placeholder="$t('g.start_date')" :end-placeholder="$t('g.end_date')"
                                :shortcuts="shortcuts">
                            </el-date-picker>
                        </div> -->
                    </div>
                    <div class="chart-content">
                        <div>
                            <div>{{ $t('bigScreen.BAT_swapping') }}</div>
                            <div>{{ operateData.ExchangeOrderTotal }}</div>
                        </div>
                        <div>
                            <div>{{ $t('bigScreen.total_BAT_number') }}</div>
                            <div>{{ leftData.TatolCount }}</div>
                        </div>
                        <div>
                            <div>{{ $t('bigScreen.total_vehicle') }}</div>
                            <div>{{ operateData.CarTotal }}</div>
                        </div>
                        <div>
                            <div>{{ $t('bigScreen.success_rate_swapping') }}</div>
                            <div>{{ operateData.ExchangeSuccessRate }}</div>
                        </div>
                        <div>
                            <div>{{ $t('bigScreen.battery_rentals') }}</div>
                            <div>{{ leftData.InManCount }}</div>
                        </div>
                        <div>
                            <div>{{ $t('bigScreen.vehicle_rentals') }}</div>
                            <div>{{ operateData.CarRentTotal }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getEqs } from '@/data/Equipments'
import { reactive, onMounted, VueElement, nextTick, ref, onBeforeUnmount, watch } from 'vue'
import '../../assets/bigScreen/flexible'
import { toDay } from '@/common/func'
import i18n from '@/data/i18n/index'
import axios from "axios";
import { ElMessage, dayjs } from "element-plus";
import echartONe from '@/views/bigScreen/leftOneEchart'
import echartThree from '@/views/bigScreen/leftThreeEchart'
import { shortcuts2, updateOperators, operatorList, cascade_options_direct } from "@/data/default";
import leftTwoEchart from '@/views/bigScreen/leftTwoEchart'
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
import storage from '@/common/storage'
const t = i18n.global.t
const tableData = [{ Id: 'EBE2024051502306233', Nickname: '张志勇', Money: t('alertRecord.result.success'), Mobile: '16600024092' }, { Id: '	EBE2024051502273888', Nickname: 'Wx1898', Money: t('alertRecord.result.success'), Mobile: '13691036770' }, { Id: 'EBE2024051502277424', Nickname: 'Wx2068', Money: t('alertRecord.result.success'), Mobile: '	13366110311' }, { Id: '	EBE2024051502263439', Nickname: '	赵永钦', Money: t('alertRecord.result.success'), Mobile: '13581976144' }, { Id: '	EBE2024051502262809', Nickname: '刘贵良', Money: t('alertRecord.result.success'), Mobile: '13575861581' }, { Id: '	EBE2024051502240208', Nickname: 'Wx2421', Money: t('alertRecord.result.success'), Mobile: '	15710044677' }, { Id: 'EBE2024051502222416', Nickname: 'Wx2058', Money: t('alertRecord.result.success'), Mobile: '13691235793' }, { Id: 'EBE2024051502221302', Nickname: 'Wx2724', Money: t('alertRecord.result.success'), Mobile: '13161718589' },]

const formData = reactive({
    props: {
        children: "Children",
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
    },
    tableData,
})
const enLang = ref(false)
let searchAllTime = ref('')
let searchAgentPath = ref('')
const search = reactive({
    allTime: '',
    AgentCode: ''
})
let leftData = reactive({
    InDevCount: 0,
    InIdleCount: 0,
    InManCount: 0,
    OfflineCount: 0,
    OnlineCount: 0,
    TatolCount: 0,
    OnlineBox: 0,
    OfflineBox: 0,
    NoReleaseBox: 0,
})
let map = {}
const incomeData = reactive({
    allEarnings: 0,
    allRefund: 0,
    todayEarnings: 0,
    todayRefund: 0,
})
const userData = reactive({
    newUser: 0,
    totalUser: 0,
    validUser: 0,
})
const operateData = reactive({
    ExchangeOrderTotal: 0,
    ExchangeOrderSuccess: 0,
    ExchangeSuccessRate: 0,
    CarTotal: 0,
    CarRentTotal: 0,
})
onMounted(async () => {
    if (storage.get('locale') == 'En') {
        enLang.value = true
    }
    func.getDevStat()
    func.getMapData()
    func.getIncome()
    func.getUserData()
    func.getOperateData()
}
)
watch(searchAllTime, () => {
    func.getOperateData()
    func.getUserData()
})
watch(searchAgentPath, () => {
    func.getOperateData()
    func.getUserData()
    func.getDevStat()
    func.getIncome()
})
const func = {
    async getMapData() {
        formData.tableData = tableData
        const AMap = window.AMap
        let center = [11.2, 11.2]
        map = new AMap.Map('bigScreenMap', {
            zoom: 1, // 设置地图缩放级别
            // pitch: 890, // 设置地图俯仰角
            center: [116.397428, 39.90923],//设置地图中心点坐标
            resizeEnable: true,
            // mapStyle: 'amap://styles/f9db5bb4478d355b9c1fc70c756671c8',
            mapStyle: 'amap://styles/blue',
            features: ['bg', 'road'],
        });

        let allEQs = await getEqs()
        formData.data = Object.assign([], allEQs)
        console.log(formData.data);
        const image = require('@/assets/img/box-online.png')
        const icon = new AMap.Icon({
            size: new AMap.Size(32, 41),
            image,
        });
        let markerList = []
        for (const item of formData.data) {
            const marker = new AMap.Marker({
                position: [item.lng, item.lat],
                icon
            })
            markerList.push(marker)
        }

        map.add(markerList);
    },
    async getDevStat() {
        let params = {}
        if (searchAgentPath) {
            params.CodePath = searchAgentPath.value
        }
        let res = await axios.get('/admin/battery/getstat', {
            params
        })
        if (res) {
            // leftData = res
            // leftData = { ...res }
            leftData.InDevCount = res.InDevCount
            leftData.InIdleCount = res.InIdleCount
            leftData.InManCount = res.InManCount
            leftData.OnlineBox = res.OnlineBox
            leftData.OfflineBox = res.OfflineBox
            leftData.TatolCount = res.TatolCount
            leftData.NoReleaseBox = res.NoReleaseBox
            // leftData.InDevCount = res.InDevCount
            // leftData.InDevCount = res.InDevCount
        }
        console.log(JSON.stringify(leftData), 1820);

    },
    async getIncome() {
        let params = {}
        if (searchAgentPath) {
            params.codePath = searchAgentPath.value
        }
        let res = await axios.get('/admin/homescreen/getincome', {
            params
        })
        if (res) {
            incomeData.allEarnings = res.RealIncome
            incomeData.allRefund = res.RefundTotal
            incomeData.todayEarnings = res.TodayRealIncome
            incomeData.todayRefund = res.TodayRefund
        }

    },
    async getUserData() {
        let params = {}
        if (searchAgentPath) {
            params.codePath = searchAgentPath.value
        }
        if (searchAllTime.value) {
            params.startAt = dayjs(searchAllTime.value[0] + ' 00:00:01').unix()
            params.endAt = dayjs(searchAllTime.value[1] + ' 23:59:59').unix()
        }
        let res = await axios.get('/admin/homescreen/getusernum', {
            params
        })
        if (res) {
            userData.newUser = res.newUser
            userData.totalUser = res.totalUser
            userData.validUser = res.validUser
        }
    },
    async getOperateData() {
        let params = {}
        if (searchAgentPath) {
            params.codePath = searchAgentPath.value
        }
        if (searchAllTime.value) {
            params.startAt = dayjs(searchAllTime.value[0] + ' 00:00:01').unix()
            params.endAt = dayjs(searchAllTime.value[1] + ' 23:59:59').unix()
        }
        let res = await axios.get('/admin/homescreen/operationdata', {
            params
        })
        if (res) {
            operateData.ExchangeOrderTotal = res.ExchangeOrderTotal
            operateData.ExchangeOrderSuccess = res.ExchangeOrderSuccess
            operateData.ExchangeSuccessRate = res.ExchangeSuccessRate
            operateData.CarTotal = res.CarTotal
            operateData.CarRentTotal = res.CarRentTotal
        }
    },
}
onBeforeUnmount(() => {
    // 销毁地图，防止卡顿
    map.destroy()
})




</script>
<style lang="scss" scoped>
.content1 {
    width: 100vw;
    height: 100vh;
    background-image: url("../../assets/bigScreen/BD_BG.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;

    >.header {
        width: 100%;
        background-size: 100% 100px;
        background-image: url("../../assets/bigScreen/BD_top.png");
        background-repeat: no-repeat;
        position: relative;
        height: 80px;
        line-height: 65px;

        .header-title {
            height: 80px;
            line-height: 65px;
            color: aliceblue;
            font-size: 26px;
        }

        .cur-time {
            color: aliceblue;
            font-size: 14px;
            position: absolute;
            right: 20px;
            top: 0px;
            left: 20px;
            display: flex;
            width: 98%;
            justify-content: space-between;

            >.operation {
                margin-right: 60px;
                // float: left;
                height: 28px;
                line-height: 57px;

                .inp2 {
                    width: 180px;
                }

                :deep(.el-select .el-input) {
                    --el-input-bg-color: #092141;
                    // width: 60%;
                    --el-input-placeholder-color: #e1eafb;
                    --el-input-border-color: #9ca7c2;
                    height: 24px;
                    font-size: 12px;

                }

                :deep(.el-select .el-select__wrapper) {
                    background-color: #092141;
                    color: #f7f0f0;
                }

                :deep(.el-select .el-el-select__suffix) {
                    color: #f7f0f0;
                }

                :deep(.el-select .el-select__placeholder) {
                    color: #f7f0f0;
                }


            }



        }
    }

    >.main {
        flex: 8;
        display: flex;
        padding: .05rem 0.1rem;


        >.left {
            flex: 1;
            display: flex;
            flex-direction: column;

            >.l1 {
                .chart-content {
                    width: 100%;
                }
            }
        }

        >.center {
            flex: 1.8;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            >.map {
                width: 90%;
                flex: 3;
                position: relative;

                // background-color: pink;
                .en-select,
                .select {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    background-color: #6c7e95;
                    color: #fff;
                    padding: 0 20px;
                    border-radius: 5px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;

                    >div {
                        width: 200px;
                        text-align: left;
                    }

                    :deep(.el-radio .el-radio__label) {
                        color: #fff;
                    }

                    :deep(.is-checked .el-radio__label) {
                        color: #409eff;
                    }

                    .switch-txt {
                        margin: 0 10px;
                    }
                }

                .select {


                    >div {
                        width: 100px;
                    }
                }
            }

            >.order {
                width: 90%;
                flex: 1.3;
                border: 1px solid #5d7da8;
                // box-shadow: #a4c1e4 1px 1px 2px;
                position: relative;
                height: 100px;
                // height: 100%;
                margin: 10px 0;
                background-color: #000d20;

                >.scroll {
                    position: absolute;
                    top: 30px;
                    overflow: hidden;
                    color: #fff;
                    // height: 100px;
                    height: calc(100% - 30px);
                    width: 100%;



                }

                .item {
                    display: flex;
                    justify-content: space-around;
                    width: 100%;
                    color: #fff;
                    border: 1px solid #3362a0;

                    &:hover {
                        background-color: #1b4078;
                        color: #fff;
                    }

                    >span {
                        flex: 1;
                        height: 30px;
                        line-height: 30px;


                    }

                    >span:first-child {
                        padding: 0 20px;
                    }

                }

                >.item:first-child {
                    background-color: #1e3f70;
                    color: #e2e9ed;

                    span:nth-child(-n+3) {
                        border-right: 1px solid #3362a0;
                    }

                }

            }
        }

        >.right {
            flex: 1;
            display: flex;
            flex-direction: column;
            color: #f9f4f4;

            .r1,
            .r2,
            .r3 {
                flex: 1;
                margin-bottom: 10px;
                display: flex;
                flex-direction: column;

                >.chart-title {
                    height: .6rem;
                    color: #e2e9ed;
                    text-align: left;
                    padding-left: .5rem;
                    line-height: .6rem;
                    display: flex;
                    justify-content: space-between;
                    padding: 0 15px;

                    >.t {
                        width: 100px;
                        border: 2px solid #026a81;
                        border-radius: 5px;
                        box-shadow: #7bc4d6 1.8px 1.8px 2px;
                        text-align: center;
                    }

                    >.time {
                        height: 30px;
                        line-height: 28px;
                    }
                }

                >.chart-content {
                    flex: 4;
                    font-size: .25rem;
                }
            }

            .r1 {
                display: flex;

                .chart-content {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    row-gap: .25rem;
                    column-gap: .35rem;
                    padding: 20px 10px 10px;

                    >div {
                        display: flex;
                        border-top: 1px solid #bdc4cb;
                        border-bottom: 1px solid #bdc4cb;
                        font-size: .25rem;
                        color: #8ddbff;
                        align-items: center;

                        >div {
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 100%;
                        }

                        >div:first-child {
                            flex: 1;
                            min-width: 1.2rem;
                            background-color: #e3ecf4;
                            color: #005fb0;
                        }

                        >div:last-child {
                            flex: 2;
                            background-color: #5d7da8;
                            color: #f9f4f4;
                        }
                    }
                }
            }

            .r2 {
                .chart-content {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    row-gap: .1rem;
                    // column-gap: .1rem;
                    padding: 10px 0px 0px;

                    >div {
                        place-self: center;
                        display: flex;
                        height: 90%;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: center;

                        >div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            padding: 0 5px;
                        }

                        >div:first-child {
                            flex: 1;
                            min-width: 1.2rem;
                            background-color: #f9fafa;
                            color: #024784;
                            border-radius: 5px 5px 0px 0px;
                        }

                        >div:last-child {
                            flex: 1.3;
                            background-color: #94b9e8;
                            color: #ffffff;
                        }
                    }
                }
            }

            .r3 {
                .chart-content {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    row-gap: .1rem;
                    column-gap: .1rem;
                    padding: 10px 10px 0px;

                    >div {
                        border: 1px solid #5d7da8;
                        box-shadow: #a4c1e4 1px 1px 2px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        >div {
                            flex: 1;
                            display: flex;
                            align-items: center;
                        }

                        // >div:last-child {
                        //     color: rgb(217, 145, 145);
                        // }
                    }

                }
            }
        }

        .card {
            flex: 1;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            background-image: url("../../assets/bigScreen/BD_K_bg.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;

            >.chart-title {
                height: .6rem;
                background-image: url("../../assets/bigScreen/BD_title_bg.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
                color: #e2e9ed;
                text-align: left;
                padding-left: .5rem;
                padding-right: .39rem;
                line-height: .6rem;
                display: flex;
                justify-content: space-between;
            }

            >.chart-content {
                flex: 4;
            }
        }
    }

    :deep(.time .el-date-editor) {
        --el-input-bg-color: #092141;
        // --el-input-placeholder-color: #e1eafb;
        --el-date-editor-monthrange-width: 200px;
        --el-date-editor-daterange-width: 190px;
        --el-date-editor-datetimerange-width: 200px;

        .el-range-separator {
            color: #e1eafb;
        }

        .el-icon {
            color: #e1eafb;
        }
    }

    :deep(.time .el-date-editor .el-range-input) {
        // --el-input-placeholder-color: #ffffff;
        width: 45%;
        color: #7faafb;

        &::placeholder {
            color: #e1eafb !important;
        }
    }

    :deep(.amap-logo) {
        position: fixed !important;
        opacity: 0 !important;
    }

    :deep(.amap-copyright) {
        opacity: 0;
    }

    :deep(.operation .el-cascader .el-input__wrapper) {

        background-color: #092141 !important;
        color: #fff !important;

        .el-input__inner {
            color: #7faafb;

            &::placeholder {
                color: #e1eafb;
            }
        }
    }
}
</style>