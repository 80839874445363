<template>
    <div class="box">
        <div id="myEchart3" ref="myEchart3" style="width:100%;height:100%"></div>
    </div>
</template>
<script setup>
import { Bottom } from '@element-plus/icons-vue';
import * as echarts from 'echarts'
import { onMounted } from 'vue';

onMounted(() => {
    const myEchartDiv = document.getElementById('myEchart3')
    const myEchart = echarts.init(myEchartDiv)
    let option = {}
    option = {
        tooltip: {
            trigger: 'item',

        },
        legend: {
            top: '3%',
            left: 'center',
            textStyle: {
                fontSize: 12,
                color: '#FFFFFF',
            },

        },
        series: [
            {
                name: '统计',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                // padAngle: 5,
                itemStyle: {
                    borderRadius: 5
                },
                label: {
                    show: false,
                    position: 'center'
                },
                // emphasis: {
                //     label: {
                //         show: true,
                //         fontSize: 40,
                //         fontWeight: 'bold'
                //     }
                // },
                labelLine: {
                    show: false
                },
                center: ['50%', '55%'],
                data: [
                    {
                        value: 1048, name: '在线数', itemStyle: {
                            color: '#07a8e3'
                        },
                    },
                    {
                        value: 735, name: '离线数', itemStyle: {
                            color: '#f5bf45'
                        },
                    },
                    {
                        value: 580, name: '未激活', itemStyle: {
                            color: 'gray'
                        },
                    },

                ]
            }
        ]
    };
    // myEchart.setOption(option)
    setTimeout(() => {
        myEchart.setOption(option)
    }, 300)
    window.addEventListener("resize", function (event) {
        myEchart.resize()
    })
})

</script>
<style lang="scss" scoped>
.box {
    color: red;
    height: 100%;
}
</style>