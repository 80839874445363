<template>
    <div class="box">
        <div id="myEchart1" ref="myEchart1" style="width:100%;height:100%"></div>
    </div>
</template>
<script setup>
import { Bottom } from '@element-plus/icons-vue';
import * as echarts from 'echarts'
import { onMounted, watch, defineProps } from 'vue';
import i18n from '@/data/i18n/index'
const t = i18n.global.t
const props = defineProps(
    {
        OnlineBox: Number,//在线设备
        OfflineBox: Number,//离线设备
        NoReleaseBox: Number,//未激活
    })
let option = {}
let myEchart = ''
watch(props, () => {

    func.setOption()
})
onMounted(() => {


    const myEchartDiv = document.getElementById('myEchart1')
    myEchart = echarts.init(myEchartDiv)
    func.setOption()
})
const func = {
    setOption() {
        option = {
            tooltip: {
                trigger: 'item',

            },
            legend: {
                top: '3%',
                left: 'center',
                textStyle: {
                    fontSize: 12,
                    color: '#FFFFFF',
                },

            },
            series: [
                {
                    name: '统计',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    // padAngle: 5,
                    itemStyle: {
                        borderRadius: 5
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    labelLine: {
                        show: false
                    },
                    center: ['50%', '55%'],
                    data: [
                        {
                            value: props.OnlineBox, name: t('bigScreen.online'), itemStyle: {
                                color: new echarts.graphic.LinearGradient(
                                    0, 1, 0, 0,
                                    [
                                        { offset: 1, color: '#089ee1' },
                                        { offset: 0, color: '#05eaf4' }
                                    ]
                                )
                            },
                        },
                        {
                            value: props.OfflineBox, name: t('bigScreen.offline'), itemStyle: {
                                color: new echarts.graphic.LinearGradient(
                                    0, 1, 0, 0,
                                    [
                                        { offset: 1, color: '#ab4245' },
                                        { offset: 0, color: '#f9afbe' }
                                    ]
                                )

                            },
                        },
                        {
                            value: props.NoReleaseBox, name: t('bigScreen.not_active'), itemStyle: {
                                color: new echarts.graphic.LinearGradient(
                                    0, 1, 0, 0,
                                    [
                                        { offset: 1, color: 'gray' },
                                        { offset: 0, color: '#cfcece' }
                                    ]
                                )
                            },
                        },

                    ]
                }
            ]
        };
        myEchart.setOption(option)
        window.addEventListener("resize", function (event) {
            myEchart.resize()
        })
    }
}

</script>
<style lang="scss" scoped>
.box {
    color: red;
    height: 100%;
}
</style>